export default {
	data() {
		return {
			sitemap: [
				{
					slug: `index`,
					name: `Home`,
					classPage: `home`,
				},
				{
					slug: `clanok`,
					name: `Article`,
					classPage: `article`
				},
				{
					slug: `stranka`,
					name: `Page`,
					classPage: `page`
				},
				{
					slug: `preview`,
					name: `Preview`,
					classPage: `article`
				},
				{
					slug: `projekt`,
					name: `Project`,
					classPage: `project`
				},
				{
					slug: `ligovinky`,
					name: `Ligovinky`,
					classPage: `article`
				},
				{
					slug: `tema-mesiaca`,
					name: `TemaMesiaca`,
					classPage: `article`
				},
				{
					slug: `podcast`,
					name: `Podcast`,
					classPage: `podcast`
				},
				{
					slug: `video`,
					name: `Video`,
					classPage: `video`
				},
				{
					slug: `diskusie`,
					name: `Discussion`,
					classPage: `discussion`
				},
				{
					slug: `diskusia`,
					name: `Discussion`,
					classPage: `discussion`
				},
				{
					slug: `kurz`,
					name: `Course`,
					classPage: `course`
				},
				{
					slug: `testy`,
					name: `Quiz`,
					classPage: `quiz`
				},
				{
					slug: `kampane`,
					name: `Campaign`,
					classPage: `campaign`
				},
				{
					slug: `kampan`,
					name: `Campaign`,
					classPage: `campaign`
				},
				{
					slug: `kategoria`,
					name: `Category`,
					classPage: `category`
				},
				{
					slug: `letak`,
					name: `Leaflet`,
					classPage: `leaflet`
				},
				{
					slug: `peerovia`,
					name: `Peerovia`,
					classPage: `peer`
				},
				{
					slug: `publikacie`,
					name: `Publications`,
					classPage: `publications`
				},
				{
					slug: `podujatie`,
					name: `Event`,
					classPage: `event`
				},
				{
					slug: `event-nezabudka`,
					name: `Event`,
					classPage: `event`
				},
				{
					slug: `potrebujem-pomoc`,
					name: `Help`,
					classPage: `static`
				},
				{
					slug: `2percenta-z-dani`,
					name: `Taxes`,
					classPage: `static`
				},
				{
					slug: `e-mailova-poradna-linky-dovery-nezabudka`,
					name: `Consulting`,
					classPage: `static`
				},
				{
					slug: `ochrana-osobnych-udajov`,
					name: `Privacy`,
					classPage: `static`
				},
				{
					slug: `otazky-a-odpovede`,
					name: `Faq`,
					classPage: `static`
				},
				{
					slug: `podporte-nas`,
					name: `Support`,
					classPage: `static`
				},
				{
					slug: `staznosti-a-spatna-vazba`,
					name: `Complaints`,
					classPage: `static`
				},
				{
					slug: `eticke-kodexy`,
					name: `Codecs`,
					classPage: `static`
				},
				{
					slug: `kto-sme`,
					name: `whoWeAre`,
					classPage: `who-we-are`
				},
				{
					slug: `partneri-a-donori`,
					name: `PartnersDonors`,
					classPage: `partners-donors`
				},
				{
					slug: `clenovia-ligy`,
					name: `Members`,
					classPage: `members`
				},
				{
					slug: `stanoviska-ligy`,
					name: `Appeals`,
					classPage: `appeals`
				},
				{
					slug: `vyzva`,
					name: `Appeals`,
					classPage: `appeals`
				},
				{
					slug: `organizacia`,
					name: `Provider`,
					classPage: `provider`
				},
				{
					slug: `vyrocne-spravy`,
					name: `AnnualReports`,
					classPage: `annual-reports`
				},
				{
					slug: `pre-media`,
					name: `ForMedia`,
					classPage: `for-media`
				},
				{
					slug: `kontakty`,
					name: `Contacts`,
					classPage: `contacts`
				},
				{
					slug: `vyhladavanie`,
					name: `Search`,
					classPage: `search`
				},
				{
					slug: `vsetky-vysledky`,
					name: `AllResults`,
					classPage: `all-results`
				},
				{
					slug: `archiv`,
					name: `Archive`,
					classPage: `archive`
				},
				{
					slug: `ocenenie-dobre-kruhy`,
					name: `GoodCirclesAward`,
					classPage: `good-circles-award`
				},
				{
					slug: `zbierka-dni-nezabudiek`,
					name: `GoodCirclesAward`,
					classPage: `zbierka-dni-nezabudiek`
				},
				{
					slug: `mapa-podpory`,
					name: `SupportMap`,
					classPage: `support-map`
				},
			]
		};
	},
	methods: {
		isMenuItemActive(sectionName) {
			return this.$route.name !== null && this.$route.name.indexOf(sectionName) !== -1;
		},
	}
}
